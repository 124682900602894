import React from 'react';
import '../css/subscriptionShimmer.css';

const Shimmer = (props) => {
   
	return (<div className="shimmer-container">
                     <div className="shimmer-left">
                        <bigbox className="shimmer-shine"></bigbox>
                        <div className="shimmer-bottom-fixed">
                        <photo className="shimmer-shine shimmer-bottom-fixed"></photo>
                        </div>
                        
                     </div>
                     <div className="shimmer-right">
                            <lines className="shimmer-shine"></lines>

                            <div>
                            <lines className="shimmer-shine"></lines>
                            <photo className="shimmer-shine"></photo>
                            <br></br>
                            <br></br>
                            <lines className="shimmer-shine"></lines>
                            </div>

                            <lines className="shimmer-shine"></lines>
                            <lines className="shimmer-shine"></lines>
                            <lines className="shimmer-shine"></lines>
                            <lines className="shimmer-shine"></lines>

                            <br/>

                           

                            <div>
                            <lines className="shimmer-shine"></lines>
                            <lines className="shimmer-shine"></lines>
                            <lines className="shimmer-shine"></lines>
                            <br></br>
                            <br></br>
                            <photo className="shimmer-shine"></photo>
                            <br></br>
                            <br></br>
                            <lines className="shimmer-shine"></lines>
                            <br></br>
                            <br></br>
                            <box className="shimmer-shine"></box>
                            </div>

                     </div>


        </div>
	);
};

export default Shimmer;


import React, { Component,lazy,Suspense } from 'react';
import './App.css';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shimmer from './UI/SubscriptionShimmer';
import whatsapp from "./images/whatsappCTA.svg";
// import FaqSection from './components/FaqSection';
// import Home from './components/Home';
// import Referral from '../src/components/Referral';
// import Terms from '../src/components/Terms';
// import Privacy from '../src/components/Privacy';
// import ReferralTerms from '../src/components/ReferralTerms';
// import Educators from './components/LiteEducators/index.js';
// import myLibraryEduction from "./components/MyLibraryEducation/MyLibraryEducation";


const Home = lazy(() => import('./components/Home'));
const Terms = lazy(() => import('./components/Terms'));
const Privacy = lazy(() => import('./components/Privacy'))
const Referral = lazy(() => import('./components/Referral'));
const FaqSection = lazy(() => import('./components/FaqSection'))
const ReferralTerms = lazy(() => import('./components/ReferralTerms'));
const myLibraryEduction = lazy(() => import('./components/MyLibraryEducation/MyLibraryEducation'))
const Educators = lazy(() => import('./components/LiteEducators/index.js'))
const myLibrary = lazy(()=>import('./components/MyLibrary/MyLibrary.js'))
const myLibraryExt = lazy(()=>import('./components/MyLibraryExt/MyLibraryExt.js'))
const SharePublicly = lazy(()=>import('./components/SharePublicly/SharePublicly.js'))
const FeeDescription = lazy(()=>import('./components/FeeDescription/FeeDescription.js'))
const TestUpgrade = lazy(()=>import('./components/Upgrade/TestUpgrade.js'))
const WebDashboard = lazy(()=>import('./components/Upgrade/WebDashboard.js'))
const AddFaculty = lazy(()=>import('./components/Upgrade/AddFaculty.js'))
const StoreUpgrade = lazy(()=>import('./components/Upgrade/StoreUpgrade.js'))
const StoreUpgradeNew = lazy(()=>import('./components/Upgrade/StoreUpgradeNew.js'))
const Subscription=lazy(()=>import('./components/Subscription/index.js'))
// const SubscriptionSuccess=lazy(()=>import('./components/Subscription/SubscriptionSuccess.js'))
const SubscriptionThanks=lazy(()=>import('./components/Subscription/SubscriptionThanks.js'))
// const Shimmer=lazy(()=>import('./components/Subscription/Shimmer.js'))
const ProtectedRoute=lazy(()=>import('./HOC/ProtectedRoutes'))

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="App">
				<BrowserRouter>
				<Suspense fallback={<p><Shimmer/></p>}>
					<Switch>

						
						<Route exact path="/" component={Home} />
						{/* <Route exact path="/faq" component={FaqSection} /> */}
						
							<Route exact path="/faq" component={FaqSection} />
							<Route exact path="/referral" component={Referral} />
							<Route exact path="/privacy" component={Privacy} />
							<Route exact path="/tos" component={Terms} />
							<Route exact path="/u/:phone" component={Educators} />
							<Route exact path="/referralTerms" component={ReferralTerms} />
							<Route exact path="/feature-update/my-library" component={myLibraryEduction} />
							<Route exact path="/feature-update/pre-loaded-content" component={myLibrary} />
							<Route exact path="/feature-update/pre-loaded-content-ext" component={myLibraryExt} />
							<Route exact path="/feature-update/share-publicly" component={SharePublicly} />
							<Route exact path="/payments/recurring-vs-non-recurring" component={FeeDescription} />
							<Route exact path="/upgrade/feat/cms" component={TestUpgrade} />
							<Route exact path="/upgrade/feat/web" component={WebDashboard} />
							<Route exact path="/upgrade/feat/faculty" component={AddFaculty} />
							<Route exact path="/upgrade/feat/store" component={StoreUpgrade} />
							<Route exact path="/upgrade/feat/bmoa" component={StoreUpgradeNew} />
							<Route exact path="/payment-page" component={Subscription} />
							{/* <ProtectedRoute exact path="/payment-page/success" component={SubscriptionSuccess}/> */}
							<ProtectedRoute exact path="/payment-page/thanks" component={SubscriptionThanks}/>
							
							{/* <Route exact path="/blog" component={Home} /> */}
						
						{/* <Route exact path="/referral" component={Referral} />
						<Route exact path="/privacy" component={Privacy} /> */}
						{/* <Route exact path="/tos" component={Terms} />
						<Route exact path="/u/:phone" component={Educators} />
						<Route exact path="/referralTerms" component={ReferralTerms} />
						<Route exact path="/feature-update/my-library" component={myLibraryEduction} /> */}
						{/* <Route exact path="/blog" component={Home} /> */}
						<Route render={() => <Redirect to="/" />} />
					</Switch>
				</Suspense>
				</BrowserRouter>
				{/* <div className="chatbot" 
					 onClick={()=>{
						window.open('https://wa.me/918588025642?text=Hi+Classplus!+Help+me+grow+my+coaching+online')
					 }}
				>
					<img src={whatsapp} alt=""/>
				</div> */}
				<ToastContainer
						style={{ fontSize: "16px"}}
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={true}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
        />
			</div>
		);
	}
}

export default App;
